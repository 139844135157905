import { Box } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { tryParseJson } from '../../../../../util/parseJson';
import { z } from 'zod';

interface AllergyAssessmentProps {
  short?: boolean;
  allergies: string;
}

const AllergyAssessment = ({ short, allergies }: AllergyAssessmentProps) => {
  const parsedAllergies: Allergy[] = parseAllergies(allergies);

  if (short) {
    return <>{parsedAllergies.map(a => a.name).join(', ')}</>;
  }

  return (
    <Box mb={-1}>
      {parsedAllergies.map((a, i) => (
        <Box mb={1} key={i}>
          <Typography
            variant="h4"
            style={{ fontWeight: 'bold' }}
            gutterBottom={Boolean(a.reaction || (a.substances?.length ?? 0) > 0)}
          >
            {a.name}
          </Typography>
          {(a.reaction || (a.substances?.length ?? 0) > 0) && (
            <Box mx={2}>
              <ul style={{ listStyle: 'initial' }}>
                {a.reaction && <li style={{ fontWeight: 'bold' }}>{a.reaction}</li>}
                {(a.substances ?? []).map((t, j) => (
                  <li key={j}>
                    <Typography style={{ fontSize: 'inherit' }}>
                      <Typography component="span">{t.name}</Typography> -{' '}
                      <Typography component="span" style={{ fontWeight: 'bold' }}>
                        {t.reaction}
                      </Typography>
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AllergyAssessment;

export interface Allergy {
  name: string;
  reaction?: string;
  substances?: Substance[];
}

interface Substance {
  name: string;
  reaction?: string;
}

export const parseAllergies = (allergies: string): Allergy[] => allergies.split('|+|').map(a => parseAllergy(a.trim()));

const parseAllergy = (allergy: string): Allergy => {
  if (allergy.endsWith(')')) {
    const open = allergy.indexOf('(');
    const name = allergy.slice(0, open).trim();
    const triggers = tryParseJson(allergy.slice(open + 1, allergy.length - 1));

    if (typeof triggers === 'string') {
      return {
        name,
        reaction: triggers,
      };
    } else {
      const parsedSubstances = substancesType.safeParse(triggers);

      return {
        name,
        reaction: typeof triggers === 'string' ? triggers : undefined,
        substances: parsedSubstances.success
          ? parsedSubstances.data.map(([name, reaction]) => ({ name, reaction: reaction || undefined }))
          : undefined,
      };
    }
  } else {
    return {
      name: allergy,
    };
  }
};

const substancesType = z.array(z.tuple([z.string(), z.string().nullable().optional()]));
