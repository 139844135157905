import React, { FC, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import get from 'lodash/get';
import { addMinutes, differenceInMinutes, format, isBefore, parse } from 'date-fns';
import {
  fullNameToDrLastName,
  fullNameToDrLastNameMiddleEast,
  fullNameToLastNameRest,
  getDelayInMinutes,
  getOrLateEntryInMinutes,
  isDimmed,
  isNoShow,
  NormalizedStatus,
  normalizeStatus,
} from '../shared/procedureUtils';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import has from 'lodash/has';
import { translateToTz } from '../../../../../util/dateTime';
import HighlightedText from '../HighlightedText';
import { useScope } from '../../../../../hooks/useScope';
import { Procedure as ProcedureT } from '../../../../../types/Procedure';
import isMiddleEast from '../../../../../util/isMiddleEast';
import { getLogEntries, isBlockNerveFn, isHelpFn, isPreOpAsDPUOptimized } from '../../tablet/utils';
import { Box } from '@material-ui/core';
import { getInOrStatusLabel } from '../statusUtils';
import { formatDuration } from '../../../../../util/duration';
import Chip from '@material-ui/core/Chip';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardCompactHeader from './components/CardCompactHeader';
import CardTiny from './components/CardTiny';
import { StaffSlotProcedureContext } from '../staff/StaffSlotContext';
import StaffShifts from '../staff/StaffShifts';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import { StaffShiftProcedureContext } from '../staff/StaffShiftContext';

const Card = styled.div<any>`
  width: 100%;
  position: absolute;
  left: 0;
  padding: ${props => (props.editMode ? '0 -.2em .375em -.2em' : '0 0.375em')};

  font-weight: 600;
  top: calc(0.4rem + ${props => props.top}%);
  height: ${props => props.length}%;
  box-sizing: border-box;
  z-index: ${props => (props.fullHeight ? 5 : 1)};
`;

const StyledChip = styled.div<any>`
  position: absolute;
  top: calc(0.4rem + ${props => props.top}%);
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Header = styled.div<any>`
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  font-size: 1em;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ${props =>
    props.isInactive
      ? css`
          opacity: 0.3;
        `
      : null}

  ${props =>
    props.isDisabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
          border: none;
          animation: none;
        `
      : null}

  ${props =>
    props.stretch
      ? css`
          height: 100%;
        `
      : null}

  ${props =>
    props.row
      ? css`
          flex-direction: row;
        `
      : null}

  ${props =>
    props.column
      ? css`
          flex-direction: column;
          height: 100%;

          > * {
            flex: 1 0 auto;
            padding-top: 0;
            padding-bottom: 0;
          }
        `
      : null}
`;

const Body = styled.div<any>`
  padding: 0.4em;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;

  label {
    opacity: ${props => (props.warning ? 1 : '.35')};
  }
`;

const StartAndEndTime = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding-left: 0.25em;
  padding-right: 0.25em;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 1px;
  min-width: 3em;
`;

const StaffMembersBox = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding-left: 0.25em;
  padding-right: 0.25em;
`;

const BodySegment = styled.div`
  display: flex;
  margin-bottom: 0.25em;
`;

export const getDisposition = (dayStart: Date, current: number, dayEnd: Date) => {
  if (current) {
    return (differenceInMinutes(current, dayStart) * 100) / differenceInMinutes(dayEnd, dayStart);
  } else {
    return 0;
  }
};

const getRelativeDuration = (dayStart: Date, duration: number, dayEnd: Date) => {
  if (duration) {
    return (duration * 100) / differenceInMinutes(dayEnd, dayStart);
  } else {
    return 10;
  }
};

export const getColor = (status: string, hospitalId?: number, defaultColor: string | null = '#00A7F7') => {
  const applyColor =
    (window.location.hostname === 'localhost' && hospitalId === 10) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 10) ||
    (window.location.hostname === 'view3.ospitek.com' && hospitalId === 3) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 2);

  if (applyColor) {
    switch (status) {
      case 'WR':
        return '#8a5117';
      case 'PRE-OP':
        return 'rgb(255, 142, 161, 75%)';
      case 'OR':
        return '#0047AB';
      case 'AnestStart':
      case 'TimeOut':
      case 'Ready':
      case 'Ongoing':
        return '#00FF00';
      case 'CallNextPatient':
        return '#00FF00';
      case 'Closing':
        return '#ffbf00';
      case 'SurgeonLeftOR':
        return '#ffbf00';
      case 'Dressing':
        return '#A020F0';
      case 'End':
      case 'AnestEnd':
        return '#F55323';
      default:
        return defaultColor;
    }
  }

  return defaultColor;
};

const FULL_HEIGHT_DURATION = 140;
const secondInMili = 1000;

export const TurnoverChip: FC<{
  dayStart: Date;
  dayEnd: Date;
  startTimePrevProcedure: string;
  durationPrevProcedure: number;
  startTimeCurrentProcedure: string;
  orOutAt?: string;
  orInAt?: string;
}> = ({
  startTimePrevProcedure,
  durationPrevProcedure,
  startTimeCurrentProcedure,
  dayStart,
  dayEnd,
  orOutAt,
  orInAt,
}) => {
  const scope = useScope();

  if (!orInAt || !orOutAt || isBefore(orInAt, orOutAt)) return null;

  const startTime1 = translateToTz(get(scope, 'hospital.timezone.id'))(parse(startTimePrevProcedure));
  const endTime = addMinutes(startTime1, durationPrevProcedure);

  const startTime2 = translateToTz(get(scope, 'hospital.timezone.id'))(parse(startTimeCurrentProcedure));
  const duration = formatDuration(orOutAt, orInAt, true);

  const minutes = differenceInMinutes(startTime2, endTime) / 2 - 15;

  return (
    <StyledChip
      top={getDisposition(dayStart, addMinutes(endTime, minutes), dayEnd)}
      length={getRelativeDuration(dayStart, 15, dayEnd)}
    >
      <Chip variant="outlined" size="small" label={duration} />
    </StyledChip>
  );
};

const minDurationForStaffMembersToShow = 35;

const Procedure: FC<{
  procedure: ProcedureT;
  dayStart: Date;
  dayEnd: Date;
  onClick: () => void;
  editMode: boolean;
  isTouchDevice: boolean;
  showBedNumber: boolean;
  isKiosk?: boolean;
  date: Date;
  inlineText?: boolean;
}> = ({ procedure, dayStart, dayEnd, onClick, editMode, isTouchDevice, showBedNumber, isKiosk, date, inlineText }) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;

  const [fullHeight, setFullHeight] = useState(false);

  let hoverTimeout: NodeJS.Timeout;

  const handleMouseEnter = () => {
    if (!fullHeight) {
      hoverTimeout = setTimeout(() => {
        setFullHeight(!fullHeight);
      }, 1 * secondInMili);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setFullHeight(false);
  };

  const handleOnClick = () => {
    if (isTouchDevice) {
      setFullHeight(!fullHeight);
      setTimeout(() => setFullHeight(false), 10 * secondInMili);
    }
    onClick();
  };

  // @ts-ignore
  const startTime = translateToTz(get(scope, 'hospital.timezone.id'))(parse(get(procedure, 'startTime')));
  const duration = !isTouchDevice
    ? procedure?.duration || 0
    : !fullHeight
    ? procedure?.duration || 0
    : FULL_HEIGHT_DURATION;
  const normalizedStatus = normalizeStatus(
    procedure?.patient?.status,
    procedure?.patient?.room,
    isMiddleEast(hospitalId)
  );
  const status = has(procedure, 'patient.id') ? normalizedStatus : 'PreAdm';

  const noShow = isNoShow(procedure);
  const orLateInMinutes = getOrLateEntryInMinutes(procedure, isMiddleEast(hospitalId));
  const isInactive = isDimmed(procedure);
  const isCanceled = get(procedure, 'isCanceled');

  const physicianName = isMiddleEast(hospitalId)
    ? fullNameToDrLastNameMiddleEast(procedure?.physician?.name)
    : fullNameToDrLastName(procedure?.physician?.name);
  const color = get(procedure, 'physician.color') || get(procedure, 'physicianColor') || '#888888';

  const anesthesiologistName = isMiddleEast(hospitalId)
    ? fullNameToDrLastName(procedure?.anesthesiologist?.name)
    : fullNameToLastNameRest(procedure?.anesthesiologist?.name);
  const anesthesiaType = procedure?.anesthesiaType;

  const delayInMinutes = getDelayInMinutes(procedure);
  const isWaiting = [ROOM_TYPES.WAITING_ROOM, ROOM_TYPES.PRE_OP].includes(get(procedure, 'patient.room.type'));
  const isHoldProcedure = get(procedure, 'patient.events.holdProcedureAt');
  const isBlockNerve = isBlockNerveFn(getLogEntries(get(procedure, 'patient')));
  const readyForSurgeon = get(procedure, 'patient.events.readyForSurgeonAt');
  const readyForOr = get(procedure, 'patient.events.readyForOrAt');
  const isDPU = isPreOpAsDPUOptimized(procedure?.patient?.room?.type, procedure?.patient?.events);
  const isHelp = isHelpFn(getLogEntries(get(procedure, 'patient')));
  const procedureColor = procedure?.color || getColor(status, hospitalId, null);

  const classes = useStyles();

  if ((duration || 0) <= 15) {
    return (
      <Card
        fullHeight={fullHeight}
        editMode={editMode}
        top={getDisposition(dayStart, startTime, dayEnd)}
        length={getRelativeDuration(dayStart, 15, dayEnd)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          className={clsx(classes.helpContainer, {
            [classes.helpContainerAnimation]:
              isHelp && ![NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
          })}
        >
          <Box
            className={clsx(classes.wrapper, {
              [classes.disabled]: [NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
              [classes.onClick]: !isInactive && onClick,
              [classes.isDpuFromOr]: isDPU,
              [classes.customBorderColor]: procedureColor,
            })}
            style={procedureColor && !isHelp ? { borderColor: procedureColor } : undefined}
            onClick={handleOnClick}
          >
            <Header
              stretch
              isInactive
              isDisabled={[NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus)}
            >
              <CardTiny
                procedure={procedure}
                physicianName={physicianName}
                anesthesiologistName={anesthesiologistName}
                anesthesiaType={anesthesiaType}
                isCanceled={isCanceled}
                noShow={noShow}
                showBedNumber={showBedNumber}
                color={color}
                status={status}
                hospitalId={hospitalId}
                inOrStatus={
                  normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                }
                stretch
              />
            </Header>
          </Box>
        </Box>
      </Card>
    );
  } else {
    return (
      <StaffShiftProcedureContext
        hospitalId={hospitalId}
        procedureId={procedure.id}
        date={format(date, 'YYYY-MM-DD')}
        staffShifts={procedure.staffShifts || []}
      >
        <Card
          fullHeight={fullHeight}
          editMode={editMode}
          top={getDisposition(dayStart, startTime, dayEnd)}
          length={getRelativeDuration(dayStart, duration <= 30 ? minDurationForStaffMembersToShow : duration, dayEnd)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            className={clsx(classes.helpContainer, {
              [classes.helpContainerAnimation]:
                isHelp &&
                !isInactive &&
                ![NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
              [classes.helpContainerBackground]: fullHeight,
            })}
          >
            <Box
              className={clsx(classes.wrapper, {
                [classes.onClick]: !isInactive && onClick,
                [classes.isDpuFromOr]: isDPU,
                [classes.customBorderColor]: procedureColor,
              })}
              style={procedureColor && !isHelp ? { borderColor: procedureColor } : undefined}
              onClick={handleOnClick}
            >
              <Box
                className={clsx(classes.header, {
                  // [classes.stretch]: (duration || 0) <= 30,
                  [classes.row]: true,
                  [classes.completed]: isInactive,
                  [classes.disabled]: [NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(
                    normalizedStatus
                  ),
                })}
              >
                <StartAndEndTime>
                  <div>{get(procedure, 'startTimeText')}</div>
                  <div>{get(procedure, 'endTimeText')}</div>
                </StartAndEndTime>
                <Box
                  className={clsx(classes.header, {
                    // [classes.stretch]: (duration || 0) <= 30,
                    [classes.column]: true,
                  })}
                  style={{ flex: '1', height: 'auto', minWidth: 0 }}
                >
                  <CardCompactHeader
                    hideStartTime={true}
                    procedure={procedure}
                    physicianName={physicianName}
                    anesthesiologistName={anesthesiologistName}
                    anesthesiaType={get(procedure, 'anesthesiaType')}
                    isCanceled={isCanceled}
                    noShow={noShow}
                    showBedNumber={showBedNumber}
                    color={get(procedure, 'physician.color') || get(procedure, 'physicianColor')}
                    status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
                    hospitalId={hospitalId}
                    inOrStatus={
                      normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                    }
                  />
                </Box>
              </Box>

              {(duration || 0) > 30 && (
                <Body>
                  {get(procedure, 'notes') && (
                    <BodySegment>
                      <HighlightedText>{get(procedure, 'notes')}</HighlightedText>
                    </BodySegment>
                  )}
                  {get(procedure, 'allergy') && (
                    <BodySegment>
                      <HighlightedText>{get(procedure, 'allergy')}</HighlightedText>
                    </BodySegment>
                  )}
                </Body>
              )}
              {fullHeight && (
                <StaffMembersBox>
                  <StaffSlotProcedureContext
                    hospitalId={hospitalId}
                    procedureId={procedure.id}
                    date={format(date, 'YYYY-MM-DD')}
                  >
                    <StaffShifts
                      staffShifts={procedure.staffShifts || []}
                      procedure={procedure}
                      isKiosk={isKiosk}
                      editableStaff={true}
                      type={'ProcedureShift'}
                    >
                      <StaffShiftMenu procedure={procedure} />
                    </StaffShifts>
                  </StaffSlotProcedureContext>
                </StaffMembersBox>
              )}
            </Box>
          </Box>
        </Card>
      </StaffShiftProcedureContext>
    );
  }
};

export const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.type === 'light' ? alpha(theme.palette.text.primary, 0.1) : '#222c59',
    borderRadius: '0.16666em',
    boxShadow: `0 0 8px ${theme.palette.type === 'light' ? alpha(theme.palette.common.black, 0.1) : '#121d4d'}`,
    overflow: 'hidden',

    '&:after': {
      content: '',
      display: 'block',
      borderRadius: '0 0 0.16666em 0.16666em',
      position: 'absolute',
      bottom: 0,
      left: '0.5em',
      right: '0.5em',
      height: '0.4em',
      background: 'linear-gradient(0, rgba(42, 52, 93, 1) 0%, rgba(42, 52, 93, 0) 100%)',
    },
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
    border: 'none',
    animation: 'none',
  },
  onClick: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'transform 100ms ease-in-out, background-color 150ms ease-in-out',

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.primary, 0.1),
    },

    '&:active': {
      backgroundColor: alpha(theme.palette.text.primary, 0.075),
    },
  },
  isDpuFromOr: {
    border: '1px solid purple',
  },
  customBorderColor: {
    border: '4px solid transparent',
  },
  completed: {
    opacity: 0.3,

    '&:after': {
      content: '',
      background: 'linear-gradient(0, rgba(34, 44, 87, 1) 0%, rgba(34, 44, 87, 0) 100%)',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: alpha(theme.palette.background.paper, 0.05),
    fontSize: '1em',
    position: 'relative',
    zIndex: 1,
  },
  stretch: {
    height: '100%',
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    height: '100%',

    '& > *': {
      flex: '1 0 auto',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  staffMembers: {
    display: 'flex',
    minWidth: 0,
    gap: '0.5em',

    '& > div': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },

  '@keyframes emphasize': {
    '0%': {
      borderColor: 'rgba(255, 0, 0, 0)',
      boxShadow: '0 0 4px rgba(255, 0, 0, 0)',
    },
    '100%': {
      borderColor: 'rgba(255, 0, 0, 1)',
      boxShadow: '0 0 30px rgba(255, 0, 0, 1)',
    },
  },
  helpContainer: {
    border: '3px solid transparent',
    height: '100%',
  },
  helpContainerAnimation: {
    animation: `1.2s $emphasize ease-in alternate infinite`,
  },
  helpContainerBackground: {
    background: theme.palette.background.default,
  },
}));

export default Procedure;
