import React, { FC } from 'react';

const sanitizePageNumber = (pageNumber: string): string => {
  const digits = pageNumber.match(/\d+/g);
  return digits?.[0] ?? pageNumber.replace(/[“”"'‘’]/g, '');
};

export const getBarcodeText = (
  providerId: number | string,
  serviceTime: string,
  formName: string,
  pageNumber: number | string
): string => {
  const sanitizedPageNumber = typeof pageNumber === 'string' ? sanitizePageNumber(pageNumber) : pageNumber;
  return `EXT-${providerId}|${serviceTime}|${formName}|${sanitizedPageNumber}`.replace(/ /g, '-');
};

export const getBarcodeTextSrc = (text: string): string =>
  `https://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${encodeURIComponent(text)}&scale=1`;

const Barcode: FC<{
  providerId: number | string;
  serviceTime: string;
  formName: string;
  pageNumber: number;
}> = ({ providerId, serviceTime, formName, pageNumber }) => {
  const show = providerId && serviceTime && formName && pageNumber;

  if (!show) {
    return null;
  }

  const barcodeText = getBarcodeText(providerId, serviceTime, formName, pageNumber);
  const src = getBarcodeTextSrc(barcodeText);

  return <img alt={barcodeText} src={src} />;
};

export default Barcode;
