import { gql } from '@apollo/client';

import { fragments as patientFragments } from './patients';
import { fragments as staffFragments } from './staff';

const baseFields = `
  id
  status
  startTime
  startTimeText
  endTimeText
  duration
  orId
  or
  isCanceled
  physicianId
  physicianHstId
  physicianName
  physicianNameHST
  physicianColor
  patientId
  patientHstId
  patientName
  patientNameUnformatted
  patientInitials
  patientDateOfBirth
  patientDateOfBirthISO
  patientAge
  patientSex
  patientHomePhone
  patientCellPhone
  caretakerCellPhone
  familyCellPhone
  patientEmailAddress
  patientAddress
  procedureTypeId
  procedureType
  procedureMod
  diagnosis
  equipment
  cptCodes
  bilaterally
  visitType
  room
  insuranceName
  policyName
  anesthesiologistId
  anesthesiologistHstId
  anesthesiologistName
  anesthesiaType
  allergy
  notes
  visit
  preferredLanguage
  procedurePriority
  color
  arrivedAt
  date
  caretakerThread {
    id
    hasUnreadMessages
  }
  patientThread {
    id
    hasUnreadMessages
  }
  vendorRepresentative {
    id
    vendor {
      name
    }
    implant
    price
    representative {
      name
      email
      phoneNumber
    }
  }
`;

export const fragments = {
  all: gql`
    fragment ProcedureData on Procedure {
      ${baseFields}
      physician {
        id
        name
        color
        notificationNumbers
        email
      }
      patient {
        id
        name
        nameUnformatted
        type
        initials
        status
        room {
          id
          type
          name
        }
        bed
        ...PatientEvents
        ...PatientLog
      }
      anesthesiologist {
        id
        name
        phoneNumber
        email
      }
      assignedVendors {
        id
        procedureId
        vendor {
          id
          name
        }
        shiftState
      }
    }
    ${patientFragments.patientEvents}
    ${patientFragments.patientLog}
  `,
  base: gql`
    fragment ProcedureBase on Procedure {
     ${baseFields}
    }
  `,
  questionnaire: gql`
    fragment Questionnaire on QuestionnaireAnswers {
      id
      questionnaireVersion
      progress
      questions
      answers
      progress
      submittedAt
      firstSubmittedAt
      interpreter
      heightFt
      heightIn
      weightLb
    }
  `,
  fromTo: gql`
    fragment ProcedureFromToData on Procedure {
      ${baseFields}
      physician {
        id
        name
        color
        notificationNumbers
        email
      }
      anesthesiologist {
        id
        name
        phoneNumber
        email
      }
    }
  `,
};

export const scheduleUserProcedures = gql`
  subscription scheduleUserProcedures($scheduleUserId: Long!, $scheduleUserType: String!, $offset: Int!, $limit: Int!) {
    scheduleUserProcedures(
      scheduleUserId: $scheduleUserId
      scheduleUserType: $scheduleUserType
      offset: $offset
      limit: $limit
    ) {
      totalCount
      entities {
        ...ProcedureData
        staffShifts {
          ...StaffShiftData
        }
      }
      hasNextPage
    }
  }
  ${fragments.all}
  ${staffFragments.shiftOnly}
`;

export const dailyProcedures = gql`
  query dailyProcedures($date: Date!) {
    procedures: dailyProcedures(date: $date) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const patientProcedures = gql`
  query patientProcedures($patientId: Long!) {
    procedures: patientProcedures(patientId: $patientId) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const ChartingData = `
  id
  questionnaire {
    id
    name
    icon
    type
    content
    order
  }
  questionnaireExchange {
    id
    procedureId
    answers
    submittedAt
  }
  completed
  defaultAnswers
`;

const ChartingCompleted = `
  id
  completed
`;

const AllChartingData = `
  preOpCharts {
    ${ChartingData}
  }
  orCharts {
    ${ChartingData}
  }
  pacuCharts {
    ${ChartingData}
  }
  physicianCharts {
    ${ChartingData}
  }
  anesthesiologistPreOpCharts {
    ${ChartingData}
  }
  anesthesiologistOrCharts {
    ${ChartingData}
  }
  anesthesiologistPacuCharts {
    ${ChartingData}
  }
`;

export const scheduledProcedure = gql`
  subscription procedure($id: Long!) {
    scheduledProcedure: procedure(id: $id) {
      ...ProcedureBase

      anesthesiologist {
        id
        name
      }

      physician {
        id
        name
      }

      visits {
        id
        startTime
        procedureType
        visit
        isCanceled
      }

      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }

      patient {
        id
        notificationNumbers
        initials
        type
      }

      entryQuestionnaire {
        ...Questionnaire
      }
      ${AllChartingData}

      procedureInstructions
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      anesthesiaReviewed
    }
  }
  ${fragments.base}
  ${fragments.questionnaire}
`;

export const inTakeForm = gql`
  subscription procedure($id: Long!) {
    registrationPackage: procedure(id: $id) {
      ...ProcedureBase
      intakeStatus

      anesthesiologist {
        id
        name
      }

      physician {
        id
        name
      }

      visits {
        id
        startTime
        procedureType
        visit
        isCanceled
      }

      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }

      patient {
        id
        notificationNumbers
        initials
        type
      }

      inTakeForm {
        ...Questionnaire
      }

      entryQuestionnaire {
        heightFt
        heightIn
        weightLb
      }
      procedureInstructions
      inTakeFormProgress
      inTakeFormInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      anesthesiaReviewed
    }
  }
  ${fragments.base}
  ${fragments.questionnaire}
`;

export const listSubscription = gql`
  subscription ($date: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    procedures(date: $date, physician: $physician, operationRoomId: $operationRoomId, isCanceled: $isCanceled) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const listProceduresFromToSubscription = gql`
  subscription ($from: Date!, $to: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    proceduresFromTo(from: $from, to: $to, physician: $physician, operationRoomId: $operationRoomId, isCanceled: $isCanceled) {
      ...ProcedureFromToData
    }
  }
  ${fragments.fromTo}
`;

export const listWithStaffShiftSubscription = gql`
  subscription ($date: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    procedures(date: $date, physician: $physician, operationRoomId: $operationRoomId, isCanceled: $isCanceled) {
      ...ProcedureData
      staffShifts {
        ...StaffShiftData
      }
    }
  }
  ${fragments.all}
  ${staffFragments.shift}
`;

export const hasScheduleAccessAllRight = gql`
  query hasScheduleAccessAllRight($scheduleUserId: Long!, $scheduleUserType: String!) {
    hasScheduleAccessAllRight(scheduleUserId: $scheduleUserId, scheduleUserType: $scheduleUserType)
  }
`;

export const unregisteredPatients = gql`
  subscription ($date: Date!) {
    patients: unregisteredProcedures(date: $date) {
      ...ProcedureBase
      readyToRegisterNotificationEnabled
      physician {
        name
      }
      events {
        id
        type
        timestamp
        channel
        content
        text
        status
      }
    }
  }
  ${fragments.base}
`;

export const arrivedUnregisteredPatients = gql`
  subscription ($date: Date!) {
    arrivals: arrivedUnregisteredProcedures(date: $date) {
      ...ProcedureBase
      readyToRegisterNotificationEnabled
      physician {
        name
      }
      events {
        id
        type
        timestamp
        channel
        content
        text
        status
      }
      arrivedAt
    }
  }
  ${fragments.base}
`;

export const setProcedureShiftState = gql`
  mutation setProcedureShiftState(
    $procedureId: Long!
    $scheduleUserType: String!
    $scheduleUserId: Long!
    $state: ShiftState!
  ) {
    setProcedureShiftState(
      procedureId: $procedureId
      scheduleUserType: $scheduleUserType
      scheduleUserId: $scheduleUserId
      state: $state
    )
  }
`;

export const scheduleProcedure = gql`
  mutation scheduleProcedure(
    $operationRoomId: Long!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String
    $patientProviderId: HstId!
    $patientName: String!
    $patientDOB: String
    $patientSex: String
    $procedureTypeId: Long!
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $vendorId: Long
    $implant: String
    $price: String
    $representative: RepresentativeInput
    $diagnosis: String
    $equipment: String
    $cptCodes: String
    $bilaterally: String
    $visitType: String
    $room: String
    $insuranceName: String
    $policyName: String
    $caretakerCellPhone: String
    $familyCellPhone: String
    $patientEmailAddress: String
    $patientAddress: String
    $procedurePriority: Int
  ) {
    scheduleProcedure(
      operationRoomId: $operationRoomId
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientProviderId: $patientProviderId
      patientName: $patientName
      patientDOB: $patientDOB
      patientSex: $patientSex
      procedureTypeId: $procedureTypeId
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      vendorId: $vendorId
      implant: $implant
      price: $price
      representative: $representative
      diagnosis: $diagnosis
      equipment: $equipment
      cptCodes: $cptCodes
      bilaterally: $bilaterally
      visitType: $visitType
      room: $room
      insuranceName: $insuranceName
      policyName: $policyName
      caretakerCellPhone: $caretakerCellPhone
      familyCellPhone: $familyCellPhone
      patientEmailAddress: $patientEmailAddress
      patientAddress: $patientAddress
      procedurePriority: $procedurePriority
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editScheduledProcedure = gql`
  mutation editScheduledProcedure(
    $procedureId: Long!
    $operationRoomId: Long!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String
    $patientProviderId: HstId!
    $patientName: String!
    $patientDOB: String
    $patientSex: String
    $procedureTypeId: Long!
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $vendorId: Long
    $implant: String
    $price: String
    $representative: RepresentativeInput
    $diagnosis: String
    $equipment: String
    $cptCodes: String
    $bilaterally: String
    $visitType: String
    $room: String
    $insuranceName: String
    $policyName: String
    $caretakerCellPhone: String
    $familyCellPhone: String
    $patientEmailAddress: String
    $patientAddress: String
    $procedurePriority: Int
  ) {
    editScheduledProcedure(
      procedureId: $procedureId
      operationRoomId: $operationRoomId
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientProviderId: $patientProviderId
      patientName: $patientName
      patientDOB: $patientDOB
      patientSex: $patientSex
      procedureTypeId: $procedureTypeId
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      vendorId: $vendorId
      implant: $implant
      price: $price
      representative: $representative
      diagnosis: $diagnosis
      equipment: $equipment
      cptCodes: $cptCodes
      bilaterally: $bilaterally
      visitType: $visitType
      room: $room
      insuranceName: $insuranceName
      policyName: $policyName
      caretakerCellPhone: $caretakerCellPhone
      familyCellPhone: $familyCellPhone
      patientEmailAddress: $patientEmailAddress
      patientAddress: $patientAddress
      procedurePriority: $procedurePriority
    )
  }
`;

export const editProcedure = gql`
  mutation (
    $id: Long!
    $operationRoom: String!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String
    $patientName: String!
    $patientAge: Age
    $patientSex: String
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $procedurePriority: Int
    $color: String
  ) {
    editProcedure(
      id: $id
      operationRoom: $operationRoom
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientName: $patientName
      patientAge: $patientAge
      patientSex: $patientSex
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      procedurePriority: $procedurePriority
      color: $color
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editProcedureAnesthesiologist = gql`
  mutation ($id: Long!, $anesthesiologistId: Long) {
    editProcedureAnesthesiologist(id: $id, anesthesiologistId: $anesthesiologistId) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editProcedurePriority = gql`
  mutation ($id: Long!, $procedurePriority: Int) {
    editProcedurePriority(id: $id, procedurePriority: $procedurePriority) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editColor = gql`
  mutation ($id: Long!, $color: String) {
    editColor(id: $id, color: $color) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editColors = gql`
  mutation ($procedureIds: [Long!]!, $color: String) {
    editColors(procedureIds: $procedureIds, color: $color)
  }
`;

export const editProcedureAnesthesiaType = gql`
  mutation ($id: Long!, $anesthesiaType: String!) {
    editProcedureAnesthesiaType(id: $id, anesthesiaType: $anesthesiaType) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const update = gql`
  mutation (
    $id: Long!
    $patientName: String
    $patientDateOfBirth: Date
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $patientEmailAddress: String
  ) {
    editProcedureEntry(
      id: $id
      patientName: $patientName
      patientDateOfBirth: $patientDateOfBirth
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      patientEmailAddress: $patientEmailAddress
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const updateMobileNumber = gql`
  mutation ($id: Long!, $patientCellPhone: String) {
    editProcedureEntry(id: $id, patientCellPhone: $patientCellPhone) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const cancelProcedure = gql`
  mutation ($id: Long!, $reason: String) {
    cancelProcedure(id: $id, reason: $reason)
  }
`;

export const reactivateProcedure = gql`
  mutation ($id: Long!) {
    reactivateProcedure(id: $id)
  }
`;

export const item = gql`
  query procedure($id: Long!) {
    scheduledProcedure: procedure(id: $id) {
      ...ProcedureBase
      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        exchangeId
      }

      patient {
        id
        notificationNumbers
      }

      entryQuestionnaire {
        ...Questionnaire
      }

      procedureInstructions
      instructionsAccepted
      anesthesiaReviewed
    }
  }
  ${fragments.base}
  ${fragments.questionnaire}
`;

export const list = gql`
  query procedures($filter: Filter) {
    scheduledProcedures: procedures(filter: $filter) {
      ...ProcedureBase
      entryQuestionnaire {
        id
        progress
        submittedAt
        firstSubmittedAt
      }
      events {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
      instructionsAccepted
    }
  }
  ${fragments.base}
`;

export const scheduledProceduresSubscription = gql`
  subscription scheduledProcedures($filter: Filter) {
    scheduledProcedures(filter: $filter) {
      ...ProcedureBase
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      questionnaireInviteSMSCount
      questionnaireInviteEmailCount
      entryQuestionnaire {
        interpreter
        heightFt
        heightIn
        weightLb
      }
      physician {
        id
        name
      }
      lastQuestionnaireInviteSMS {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }
      anesthesiaReviewed
    }
  }
  ${fragments.base}
`;

export const inTakeFormsSubscription = gql`
  subscription registrationPackages($filter: Filter) {
    registrationPackages(filter: $filter) {
      ...ProcedureBase
      intakeStatus
      inTakeFormProgress
      inTakeFormInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      inTakeFormInviteSMSCount
      inTakeFormInviteEmailCount
      entryQuestionnaire {
        interpreter
        heightFt
        heightIn
        weightLb
      }
      physician {
        id
        name
      }
      lastQuestionnaireInviteSMS {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }
      anesthesiaReviewed
    }
  }
  ${fragments.base}
`;

export const procedureStatistics = gql`
  subscription procedureStatistics($filter: Filter) {
    procedureStatistics(filter: $filter) {
      scheduledProcedures
      formsFilled
      formsNotFilled
      totalForms
      pendingInfo
      preOpCompleted
      instructionsAccepted
    }
  }
`;

export const intakeStatistics = gql`
  subscription intakeStatistics($filter: Filter) {
    intakeStatistics(filter: $filter) {
      scheduledProcedures
      totalForms
    }
  }
`;

export const scheduledProceduresViews = gql`
  subscription scheduledProceduresViews($filter: Filter) {
    scheduledProceduresViews(filter: $filter) {
      questionnairesNotSent
      incompleteQuestionnaires
    }
  }
`;

export const resendQuestionnaireInvitationSMS = gql`
  mutation resendQuestionnaireInvitationSMS($procedureId: Long!) {
    resendQuestionnaireInvitationSMS(procedureId: $procedureId)
  }
`;

export const resendInTakeFormInvitationSMS = gql`
  mutation resendInTakeFormInvitationSMS($procedureId: Long!) {
    resendInTakeFormInvitationSMS(procedureId: $procedureId)
  }
`;

export const resendRegisterReadySMS = gql`
  mutation resendRegisterReadySMS($procedureId: Long!) {
    resendRegisterReadySMS(procedureId: $procedureId)
  }
`;

export const resendQuestionnaireInvitationEmail = gql`
  mutation resendQuestionnaireInvitationEmail($procedureId: Long!) {
    resendQuestionnaireInvitationEmail(procedureId: $procedureId)
  }
`;

export const resendInTakeFormInvitationEmail = gql`
  mutation resendInTakeFormInvitationEmail($procedureId: Long!) {
    resendInTakeFormInvitationEmail(procedureId: $procedureId)
  }
`;

export const resendInstructionInvitationSMS = gql`
  mutation resendInstructionInvitationSMS($procedureId: Long!, $templateType: InstructionTemplateType!) {
    resendInstructionInvitationSMS(procedureId: $procedureId, templateType: $templateType)
  }
`;

export const resendInstructionInvitationEmail = gql`
  mutation resendInstructionInvitationEmail($procedureId: Long!, $templateType: InstructionTemplateType!) {
    resendInstructionInvitationEmail(procedureId: $procedureId, templateType: $templateType)
  }
`;

export const setStatus = gql`
  mutation setProcedureStatus($id: Long!, $status: String) {
    setProcedureStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const setIntakeStatus = gql`
  mutation setIntakeStatus($id: Long!, $status: String) {
    setIntakeStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const setAnesthesiaReviewed = gql`
  mutation setAnesthesiaReviewed($id: Long!, $anesthesiaReviewed: Boolean!) {
    setAnesthesiaReviewed(id: $id, anesthesiaReviewed: $anesthesiaReviewed) {
      id
      anesthesiaReviewed
    }
  }
`;

export const postPreopNote = gql`
  mutation postPreopNote($id: Long!, $preopNote: String!) {
    postPreopNote(id: $id, preopNote: $preopNote) {
      id
      events {
        id
        type
        timestamp
        patientPin
        channel
        user {
          id
          name
        }
        text
        status
        exchangeId
      }
    }
  }
`;

export const deletePreopNote = gql`
  mutation deletePreOpNote($id: Long!) {
    deletePreOpNote(id: $id)
  }
`;

export const postAnesthesiaNote = gql`
  mutation postAnesthesiaNote($procedureId: Long!, $note: String!) {
    postAnesthesiaNote(procedureId: $procedureId, note: $note) {
      id
      events {
        id
        type
        timestamp
        patientPin
        channel
        user {
          id
          name
        }
        text
        status
        exchangeId
      }
    }
  }
`;

export const deleteAnesthesiaNote = gql`
  mutation deleteAnesthesiaNote($noteId: Long!) {
    deleteAnesthesiaNote(noteId: $noteId)
  }
`;

export const getSurgeryInstructionsTemplates = gql`
  query surgeryInstructionsTemplates {
    surgeryInstructionsTemplates {
      id
      type
      content
    }
  }
`;

export const updateSurgeryInstructionsTemplate = gql`
  mutation updateSurgeryInstructionsTemplate($templateType: InstructionTemplateType!, $content: String!) {
    updateSurgeryInstructionsTemplate(templateType: $templateType, content: $content)
  }
`;

export const updateProcedureSurgeryInstructions = gql`
  mutation updateProcedureSurgeryInstructions($procedureId: Long!, $content: String!) {
    updateProcedureSurgeryInstructions(procedureId: $procedureId, content: $content)
  }
`;

export const resetProcedureInstructions = gql`
  mutation resetProcedureInstructions($procedureId: Long!) {
    resetProcedureInstructions(procedureId: $procedureId)
  }
`;

export const searchProcedures = gql`
  query searchProcedures($filter: ProcedureFilter!) {
    searchProcedures(filter: $filter) {
      id
      patientHstId
      patientName
      patientDateOfBirthISO
      startTime
      visit
      physicianName
      procedureType
    }
  }
`;

export const getScheduleNotificationConfiguration = gql`
  query scheduleNotificationConfiguration {
    scheduleNotificationConfiguration {
      notificationRecipients
    }
  }
`;

export const updateScheduleNotificationConfig = gql`
  mutation updateScheduleNotificationConfig(
    $notificationRecipients: [Long!]!
  ) {
    updateScheduleNotificationConfig(
      notificationRecipients: $notificationRecipients
    )
  }
`;

export default {
  item,
  list,
  scheduledProceduresSubscription,
  inTakeFormsSubscription,
  update,
  scheduledProcedure,
  inTakeForm,
  listSubscription,
  dailyProcedures,
  unregisteredPatients,
  updateMobileNumber,
};
