import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import RestoreIcon from '@material-ui/icons/Restore';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PatientInfoForm from './form-components/PatientInfo';
import ProcedureInfoForm from './form-components/ProcedureInfoForm';
import StaffForm from './form-components/StaffForm';
import VendorForm from './form-components/VendorForm';
import EquipmentForm from './form-components/EquipmentForm';
import InsuranceForm from './form-components/InsuranceForm';
import { useMutation, useQuery } from '@apollo/client';
import { cancelProcedure, editScheduledProcedure, scheduleProcedure } from '../../../../../graph/procedures';
import { checkErrors, getCreateState, getEditState, transform, validateForm } from './utils/functions';
import { ProcedureForm } from './types/types';
import { Room } from '../../../../../types/Room';
import ProceduresPreview from '../../../blockSchedule/booking/ProceduresPreview';
import { mdiFileSign, mdiNeedle, mdiPackageVariantClosed } from '@mdi/js';
import { Icon } from '@mdi/react';
import { getEndTime, Time } from '../../../../entities/schedule/util/time';
import { setValueAndResetError } from './utils/values';
import { muiThemeDark } from '../../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { Procedure } from '../../../../../types/Procedure';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Booking from '../../../../../types/Booking';
import { toLocalTime } from '../staff/StaffMember';
import physicians from '../../../../../graph/physicians';
import AlertDialog from '../../../../AlertDialog';
import ProcedureCancellationModal from '../edit/ProcedureCancellationModal';

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    overflowY: 'scroll',
  },
  form: {
    flexGrow: 1,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  toolbar: {
    minHeight: 128,
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
  },
  bottomNav: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  toolbarMain: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
}));

const Sections = [
  {
    label: 'Patient info',
    icon: <AccessibilityIcon />,
    divider: false,
    FormComponent: PatientInfoForm,
    name: 'patient',
  },
  {
    label: 'Procedure',
    icon: <RestoreIcon />,
    divider: true,
    FormComponent: ProcedureInfoForm,
    name: 'procedure',
  },
  {
    label: 'Staff',
    icon: <LocalHospitalIcon />,
    divider: true,
    FormComponent: StaffForm,
    name: 'staff',
  },
  {
    label: 'Vendor',
    icon: <Icon path={mdiPackageVariantClosed} size={'24px'} />,
    divider: true,
    FormComponent: VendorForm,
    name: 'vendor',
  },
  {
    label: 'Equipment',
    icon: <Icon path={mdiNeedle} size={'24px'} />,
    divider: true,
    FormComponent: EquipmentForm,
    name: 'equipment',
  },
  {
    label: 'Insurance',
    icon: <Icon path={mdiFileSign} size={'24px'} />,
    divider: true,
    FormComponent: InsuranceForm,
    name: 'insurance',
  },
];

const isTimeOverlapping = (time: {
  procedureStartTime: Time;
  procedureDuration: number | null | undefined;
  physicianStartTime: string;
  physicianEndTime: string;
}) => {
  const { procedureStartTime, procedureDuration, physicianStartTime, physicianEndTime } = time;
  if (!procedureStartTime || !procedureDuration) return false;

  const physicianLocalStartTime = toLocalTime(physicianStartTime);
  const procedureLocalStartTime = toLocalTime(procedureStartTime) || toLocalTime('00:00');
  if (!physicianLocalStartTime?.isBefore(procedureLocalStartTime!)) return false;

  const endLocalTime = toLocalTime(getEndTime(procedureStartTime, procedureDuration)!);
  const physicianLocalEndTime = toLocalTime(physicianEndTime);
  if (physicianLocalEndTime?.isAfter(endLocalTime!)) return true;

  return false;
};

const resolveDefaultPhysician = (
  bookings: Booking[] | undefined,
  startTime: Time,
  duration: number | undefined | null
) => {
  if (!startTime || !duration || bookings === undefined || bookings.length === 0) return undefined;

  const overlapsId = bookings.map(booking =>
    isTimeOverlapping({
      procedureStartTime: startTime,
      procedureDuration: duration,
      physicianStartTime: booking.startTime,
      physicianEndTime: booking.endTime,
    })
      ? booking.physician?.id
      : undefined
  );

  return overlapsId.find(id => !!id); // undef or id ? --> check
};

export const ScheduleProcedureForm: FC<{
  procedureId?: number;
  procedure?: Procedure;
  date: Date;
  operationRooms: Room[];
  operationRoom: Room;
  timeRange: {
    startTime: string;
    duration: number;
  };
  handleClose: () => void;
  showGraphs?: boolean;
}> = ({ procedureId, procedure, date, operationRooms, operationRoom, timeRange, handleClose, showGraphs }) => {
  const classes = useStyles();
  const refs = useRef([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const toggleCancelModal = () => {
    setCancelModal(v => !v);
  };

  const onCancelProcedure = () => {
    handleCloseMenu();
    handleClose();
  };

  const alertOptions = {
    close: {
      title: 'Do you want to stop filling out the form',
      subtitle: 'The data you filled in the form will not be saved if you close the dialog.',
      onConfirm: handleClose,
    },
    delete: {
      title: 'Caution!',
      subtitle: 'Are you sure you want to cancel this procedure?',
      onConfirm: async () => {
        procedureId ? toggleCancelModal() : handleClose();
      },
    },
  };

  const [alertContent, setAlertContent] = useState(alertOptions.close);

  const [cancelProcedureMutation] = useMutation(cancelProcedure);

  const handleCancelProcedure = () => {
    setOpenAlert(true);
    setAlertContent(alertOptions.delete);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const editMode = !!procedureId && !!procedure;

  const initialState = editMode
    ? getEditState(procedure as Procedure, operationRoom, date)
    : getCreateState(date, operationRoom, timeRange);

  const [submit] = useMutation(editMode ? editScheduledProcedure : scheduleProcedure);

  const [tab, setTab] = useState(0);

  const [formValues, setFormValues] = useState<ProcedureForm>(initialState);

  const handleSave = async () => {
    const error = {
      found: false,
    };
    const formValuesLocal = validateForm(formValues);
    setFormValues(formValuesLocal);
    checkErrors(formValuesLocal, error);

    if (!error.found) {
      const data = transform(formValues);
      const variables = editMode ? { procedureId, ...data } : data;
      await submit({
        variables,
      });
      handleClose();
    }
  };

  const scrollToSection = (index: number) => {
    // @ts-ignore
    refs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      top: 100,
    });
  };

  const [openAlert, setOpenAlert] = useState(false);

  const room = operationRooms?.find(e => e.id === formValues?.procedure?.room?.id?.value);
  const selectedDate = formValues?.procedure?.timeInfo?.dateOfService?.value;
  const startTime = formValues?.procedure?.timeInfo?.timeRange?.startTime?.value;
  const duration = formValues?.procedure?.timeInfo?.timeRange?.duration?.value;

  const defaultPhysicianId = resolveDefaultPhysician(room?.bookings, startTime, duration);

  const { data } = useQuery(physicians.list);
  const unpackedPhysicians = useMemo(() => (!!data ? Object.values(data)[0] : []), [data]);

  useEffect(() => {
    if (!!defaultPhysicianId) {
      // @ts-ignore
      const physician: { id: number; name: string; email: string; notificationNumbers: string } =
        // @ts-ignore
        unpackedPhysicians.find(physician => physician.id === defaultPhysicianId);
      const formPhysician = {
        id: { value: physician?.id },
        name: { value: physician?.name },
        email: { value: physician?.email },
        notificationNumbers: { value: physician?.notificationNumbers },
      };
      setFormValues({
        ...formValues,
        //@ts-ignore
        staff: { physician: formPhysician }, // It will remove errors?
      });
    }
  }, [defaultPhysicianId, unpackedPhysicians]);

  if (cancelModal && procedureId) {
    return <ProcedureCancellationModal procedureId={procedureId} onDone={onCancelProcedure} />;
  }
  return (
    <MUIThemeProvider theme={muiThemeDark}>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={true}
        onClose={() => {
          setOpenAlert(true);
        }}
        PaperProps={{
          style: {
            overflowY: 'hidden',
          },
        }}
      >
        <AppBar elevation={4} position="fixed" className={classes.appBar} style={{ position: 'sticky' }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarMain}>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Create procedure
              </Typography>
              <Button autoFocus color="inherit" onClick={handleSave}>
                Save
              </Button>
              <>
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem onClick={handleCancelProcedure}>Cancel Procedure</MenuItem>
                </Menu>
              </>
            </div>
            <BottomNavigation value={tab} showLabels className={classes.bottomNav}>
              {Sections.map((section, i) => (
                <BottomNavigationAction
                  key={i}
                  onClick={_ => {
                    setTab(i);
                    scrollToSection(i);
                  }}
                  label={section.label}
                  icon={section.icon}
                />
              ))}
            </BottomNavigation>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Box width="100%">
                {Sections.map(({ FormComponent, divider, name }, i) => (
                  <Fragment key={i}>
                    {/* @ts-ignore */}
                    <Box pt={2} mt={i === 0 ? 0 : 2} ref={el => (refs.current[i] = el)}>
                      <FormComponent
                        //@ts-ignore
                        value={formValues[name]}
                        //@ts-ignore
                        setValue={value => setFormValues({ ...formValues, [name]: value })}
                      />
                    </Box>
                  </Fragment>
                ))}
              </Box>
            </Grid>
            <Grid item md={4}>
              {!!formValues?.procedure?.room?.id?.value && (
                <ProceduresPreview
                  date={selectedDate}
                  room={room}
                  showOverlay={true}
                  editMode={editMode}
                  startTime={startTime}
                  duration={duration}
                  setStartTime={(val: Time) =>
                    setFormValues(
                      setValueAndResetError('procedure.timeInfo.timeRange.startTime', val as string, formValues)
                    )
                  }
                  setDuration={(val?: number | null) =>
                    setFormValues(
                      setValueAndResetError('procedure.timeInfo.timeRange.duration', val as number, formValues)
                    )
                  }
                  showGraphs={showGraphs}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <AlertDialog
        title={alertContent.title}
        subtitle={alertContent.subtitle}
        open={openAlert}
        setOpen={setOpenAlert}
        onConfirm={alertContent.onConfirm}
      />
    </MUIThemeProvider>
  );
};
