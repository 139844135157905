import { red } from '@material-ui/core/colors';
import { Chip, SvgIcon } from '@material-ui/core';
import React, { useState, MouseEvent } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AllergyAssessment, { Allergy, parseAllergies } from './AllergyAssessment';

interface AllergyWarningProps {
  allergies: string;
}

function AllergyIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill={props.style.color || '#000'}
          d="M19.75 5.33a1.25 1.25 0 0 0-1.25 1.25v4.59h-.83V4.08a1.25 1.25 0 0 0-2.5 0v7.09h-.84V3.25a1.25 1.25 0 1 0-2.5 0v7.92H11V5.33a1.25 1.25 0 0 0-2.5 0v9.93l-3.59-2a1 1 0 0 0-.5-.14a1 1 0 0 0-.66.25l-1.08 1L9.21 21a3.29 3.29 0 0 0 2.37 1h6.09A3.33 3.33 0 0 0 21 18.67V6.58a1.25 1.25 0 0 0-1.25-1.25M11 15a1 1 0 1 1 1-1a1 1 0 0 1-1 1m2 3a1 1 0 1 1 1-1a1 1 0 0 1-1 1m5-2a1 1 0 1 1 1-1a1 1 0 0 1-1 1m-1 3a1 1 0 1 1 1-1a1 1 0 0 1-1 1m-2-4a1 1 0 1 1 1-1a1 1 0 0 1-1 1Z"
        />
      </svg>
    </SvgIcon>
  );
}

const AllergyWarning = ({ allergies }: AllergyWarningProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Chip
        icon={<AllergyIcon style={{ color: red[600] }} />}
        classes={{
          root: classes.chipAllergies,
        }}
        size="small"
        label={<AllergyAssessment short allergies={allergies} />}
        onClick={handleOpen}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onBackdropClick={handleClose}
        onClose={handleClose}
        onClick={e => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Allergies</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <AllergyAssessment allergies={allergies} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  chipAllergies: {
    backgroundColor: red[100],
    color: red[900],
    minWidth: 0,
  },
}));

export default AllergyWarning;
