import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { compose, withProps } from 'recompose';
import { breakpoint } from '../../se/utilities/responsive';
import {
  analyticsConfigMutation,
  analyticsConfigQuery,
  dashboardQuery,
  sendAnalyticsReport,
} from '../../graph/dashboard';
import Filters from './analytics/Filters';
import GraphLayout from './analytics/v1/GraphLayout';
import TitleAction from '../../se/components/TitleAction';
import withFilterQueryString from '../../se/hocs/withFilterQueryString';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { CenteredSpinner } from '../../se/components/Spinner';
import { RouterlessModal } from '../../se/components/Modal';
import Form from '../../se/components/Form';
import get from 'lodash/get';
import useModal from '../../hooks/useModal';
import { withLabel } from '../../se/components/Label';
import ReportRecipients from './sensor/ReportRecipients';
import ObjectInput from '../../se/components/inputs/ObjectInput';
import Box from '@material-ui/core/Box';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { NamedRange } from '../core/DatePicker';
import { YearMonth } from '@js-joda/core';
import TextInput from '../../se/components/inputs/TextInput';
import NumberInput from '../../se/components/inputs/NumberInput';
import HasPostOpNumberInput from '../../se/components/inputs/HasPostOpNumberInput';
import toArray from 'lodash/toArray';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import GeneralAnalytics from './analytics/GeneralAnalytics';
import ORUtilizationAnalytics from './analytics/ORUtilizationAnalytics';
import PhysicianUtilizationAnalytics from './analytics/PhysicianUtilizationAnalytics';
import HospitalRating from './analytics/HospitalRating';
import PWAInstallBanner from '../../se/components/PWAInstallBanner';
import pick from 'lodash/fp/pick';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import TabNavigation from './sensor/TabNavigation';
import { useScope } from '../../hooks/useScope';
import { withScope } from '../../contexts/ScopeContext';
import PDFAnalyticsButton from './analytics/pdf/PDFAnalyticsButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { SendingSetting } from '../entities/notificationTemplate/NotificationTemplates';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Aggregation, AggregationContext } from './analytics/Aggregation';

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    margin: 'auto',
  },
});

const Stats = withStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr auto',
    },
  },
}))(Box);

export const roundValue = (value: number, digits: number = 1) =>
  value ? (Math.round(value * 100) / 100).toFixed(digits) : 0;
const AnalyticsConfigInputV1 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
  },
})(ObjectInput);
const AnalyticsConfigInputV2 = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
    totalPatientsThreshold: compose(
      withLabel('Total Patients Threshold'),
      withProps({ name: 'totalPatientsThreshold' })
    )(NumberInput),
    waitingRoomTimeThreshold: compose(
      withLabel('Waiting Room Time Threshold'),
      withProps({ name: 'waitingRoomTimeThreshold' })
    )(NumberInput),
    preopTimeThreshold: compose(
      withLabel('PreOp Time Threshold'),
      withProps({ name: 'preopTimeThreshold' })
    )(NumberInput),
    readyForORThreshold: compose(
      withLabel('Ready for OR to PreOp Exit Threshold'),
      withProps({ name: 'readyForORThreshold' })
    )(NumberInput),
    orUtilThreshold: compose(
      withLabel('OR Utilization Threshold'),
      withProps({ name: 'orUtilThreshold' })
    )(NumberInput),
    orTimeThreshold: compose(withLabel('OR Time Threshold'), withProps({ name: 'orTimeThreshold' }))(NumberInput),
    cleaningTimeThreshold: compose(
      withLabel('Cleaning Time Threshold'),
      withProps({ name: 'cleaningTimeThreshold' })
    )(NumberInput),
    popiTimeThreshold: compose(
      withLabel('OR Turnover Time Threshold'),
      withProps({ name: 'popiTimeThreshold' })
    )(NumberInput),
    pacuTimeThreshold: compose(withLabel('Pacu Time Threshold'), withProps({ name: 'pacuTimeThreshold' }))(NumberInput),
    postOpTimeThreshold: compose(withScope, withProps({ name: 'postOpTimeThreshold' }))(HasPostOpNumberInput),
  },
})(ObjectInput);

const DetailedReportButton: FC = () => {
  const { open, openModal, closeModal } = useModal();
  const [mutate] = useMutation(analyticsConfigMutation);
  const { data, loading } = useQuery(analyticsConfigQuery);
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  const handleSubmit = async values => {
    const monthlyReportRecipients = toArray(get(values, 'monthlyReportRecipients'));
    const weeklyReportRecipients = toArray(get(values, 'weeklyReportRecipients'));

    const totalPatientsThreshold = get(values, 'totalPatientsThreshold');
    const waitingRoomTimeThreshold = get(values, 'waitingRoomTimeThreshold');
    const preopTimeThreshold = get(values, 'preopTimeThreshold');
    const readyForORThreshold = get(values, 'readyForORThreshold');
    const orUtilThreshold = get(values, 'orUtilThreshold');
    const orTimeThreshold = get(values, 'orTimeThreshold');
    const pacuTimeThreshold = get(values, 'pacuTimeThreshold');
    const cleaningTimeThreshold = get(values, 'cleaningTimeThreshold');
    const popiTimeThreshold = get(values, 'popiTimeThreshold');
    const postOpTimeThreshold = get(values, 'postOpTimeThreshold');

    const result = await mutate({
      variables: {
        monthlyReportRecipients,
        weeklyReportRecipients,
        totalPatientsThreshold,
        waitingRoomTimeThreshold,
        preopTimeThreshold,
        readyForORThreshold,
        orUtilThreshold,
        orTimeThreshold,
        pacuTimeThreshold,
        cleaningTimeThreshold,
        popiTimeThreshold,
        postOpTimeThreshold,
      },
      refetchQueries: [{ query: analyticsConfigQuery }],
    });

    if (get(result, 'data.updateAnalyticsConfiguration')) {
      closeModal();
    }
  };
  return (
    <div>
      <IconButton className="material-icons" onClick={openModal}>
        <SettingsIcon />
      </IconButton>
      {open && (
        <RouterlessModal
          title={'Analytics Global Configuration'}
          onClose={closeModal}
          fullWindow={window.innerWidth < breakpoint.md}
        >
          {!loading ? (
            <Form
              autoFocus
              initialValue={get(data, 'analyticsConfiguration')}
              input={analyticsV2 ? AnalyticsConfigInputV2 : AnalyticsConfigInputV1}
              label={'Save'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          ) : (
            <CenteredSpinner />
          )}
        </RouterlessModal>
      )}
    </div>
  );
};

const MonthYearInput = withProps({
  schema: {
    monthYear: withLabel('Enter month')(TextInput),
  },
})(ObjectInput);

const Title: FC<{ filter: any }> = ({ filter }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [reportQueryWorking, setReportQueryWorking] = React.useState<boolean>(false);
  const [lastDetailedReport, setLastDetailedReport] = React.useState<any>(null);
  const { open, openModal, closeModal } = useModal();
  const [sendReport] = useMutation(sendAnalyticsReport);

  useEffect(() => {
    if (lastDetailedReport !== null) {
      const timeout = setTimeout(() => {
        setLastDetailedReport(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [lastDetailedReport]);

  const handleSubmit = async values => {
    await sendReport({
      variables: {
        monthYear: values.monthYear,
      },
    });
    closeModal();
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography component="h1" variant="h2">
        Analytics
      </Typography>
      <Box display="flex">
        <PDFAnalyticsButton filter={filter} />
        <DetailedReportButton />
        {open && (
          <RouterlessModal
            title={'Send Analytics Report'}
            onClose={closeModal}
            fullWindow={window.innerWidth < breakpoint.md}
          >
            <Form
              autoFocus
              initialValue={{ monthYear: YearMonth.now().toString() }}
              input={MonthYearInput}
              label={'Send'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </RouterlessModal>
        )}
      </Box>
    </Box>
  );
};

export const AnalyticsLayout: FC<{ loading: boolean; children: React.ReactNode }> = ({ loading, children }) =>
  !loading ? (
    <Box mt={2}>
      <Stats>{children}</Stats>
    </Box>
  ) : (
    <CenteredSpinner style={{ height: '30em' }} />
  );

const pickFilter = pick(['physician', 'procedureType', 'dateRange']);

const AnalyticsV1: FC<{ filter: any; setFilter: any; isBusinessManager: boolean }> = ({
  filter,
  setFilter,
  isBusinessManager,
}) => {
  const { dateRange, ...rest } = filter || {};
  // @ts-ignore
  const { data, loading } = useQuery(dashboardQuery, {
    variables: { filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) } },
    fetchPolicy: 'network-only',
  });
  const statistics = data?.dashboard || [];

  return (
    <Fragment>
      <TitleAction
        Title={() => <Title filter={filter} />}
        actionStyle={{
          style: { gridTemplateColumns: '1fr', alignItems: 'start' },
        }}
      >
        <Filters onChange={setFilter} value={filter} />
      </TitleAction>

      <PWAInstallBanner />

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        {statistics.map((stat, i) => (
          <GraphLayout key={`${stat.id}-${i}`} {...stat} />
        ))}
      </AnalyticsLayout>
    </Fragment>
  );
};

const tabs = match => [
  {
    id: 'general',
    name: 'General',
    pathname: match.url,
  },
  {
    id: 'operating-room',
    name: 'Analysis by OR',
    pathname: `${match.url}/operating-room`,
  },
  {
    id: 'physician',
    name: 'Analysis by Physician',
    pathname: `${match.url}/physician`,
  },
];

interface AggregationPickerProps {
  value: Aggregation;
  onChange: (value: Aggregation) => void;
}

const AggregationPicker = ({ value, onChange }: AggregationPickerProps) => {
  const handleAggregationChange = (_: React.MouseEvent<HTMLElement>, value: any) => {
    const parsed = Aggregation.safeParse(value);
    onChange(parsed.success ? parsed.data : 'average');
  };

  return (
    <Box display="flex" alignItems="center">
      <ToggleButtonGroup size="small" value={value} exclusive onChange={handleAggregationChange}>
        <ToggleButton value="average">Average</ToggleButton>
        <ToggleButton value="median">Median</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const AnalyticsV2: FC<{ filter: any; setFilter: any; isBusinessManager: boolean } & RouteComponentProps> = ({
  filter,
  setFilter,
  isBusinessManager,
  location,
  match,
}) => {
  const shouldHideInputs = location.pathname.includes(`/operating-room`) || location.pathname.includes(`/physician`);

  const initialAggregation = useMemo(() => {
    const aggregation = localStorage.getItem('analytics-aggregation');
    const parsed = Aggregation.safeParse(aggregation);
    return parsed.success ? parsed.data : 'average';
  }, []);

  const [aggregation, setAggregation] = useState<Aggregation>(initialAggregation);

  const handleAggregationChange = (value: Aggregation) => {
    setAggregation(value);
    localStorage.setItem('analytics-aggregation', value);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Title filter={filter} />
        <Box mt={1} mb={3}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <TabNavigation tabs={tabs(match)} location={location} />
          <Switch>
            <Route
              path={`${match.path}/operating-room`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
            <Route
              path={`${match.path}/physician`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
          </Switch>
          <Filters
            onChange={setFilter}
            value={filter}
            hideProcedureTypeSelectInput={shouldHideInputs}
            hidePhysicianSelectInput={shouldHideInputs}
            reload={false}
          />
        </Box>
      </Box>
      <AggregationContext.Provider value={aggregation}>
        <Switch>
          <Route
            path={`${match.path}/operating-room`}
            render={() => <ORUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`${match.path}/physician`}
            render={() => <PhysicianUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`*`}
            render={() => (
              <GeneralAnalytics filter={filter} setFilter={setFilter} isBusinessManager={isBusinessManager} />
            )}
          />
        </Switch>
      </AggregationContext.Provider>
    </>
  );
};

const Analytics: FC<
  {
    filter: any;
    setFilter: any;
    isBusinessManager: boolean;
  } & RouteComponentProps
> = ({ filter, setFilter, isBusinessManager, location, match }) => {
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  if (analyticsV2) {
    return (
      <AnalyticsV2
        location={location}
        match={match}
        filter={filter}
        setFilter={setFilter}
        isBusinessManager={isBusinessManager}
      />
    );
  }

  return <AnalyticsV1 filter={filter} setFilter={setFilter} isBusinessManager={isBusinessManager} />;
};

export default compose(
  withSession(unpackSessionObject),
  withFilterQueryString({
    dateRange: NamedRange.lastSevenDays(),
  })
  // @ts-ignore
)(Analytics);
